<template>
  <div id="page-user-view">
    <vs-alert color="danger" title="User Not Found" :active.sync="userNotFound">
      <span>User record with id: {{ $route.params.userId }} not found.</span>
      <span>
        <span>Check</span>
        <router-link
          :to="{ name: 'users-list' }"
          class="text-inherit underline"
        >
          All Users
        </router-link>
      </span>
    </vs-alert>

    <div v-if="userData" id="user-data">
      <vx-card title="Account Details" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div id="avatar-col" class="vx-col">
            <div class="img-container mb-4">
              <img class="user-profile-img" :src="userData.photo" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div id="account-info-col-1" class="vx-col flex-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ userData.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ userData.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile</td>
                <td>{{ userData.mobile }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Country</td>
                <td>{{ countryName(userData.country) }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div id="account-info-col-2" class="vx-col flex-1">
            <table>
              <tr>
                <td class="font-semibold">Verification status</td>
                <td>
                  <vs-chip
                    transparent
                    :color="verificationStatusColor(userData.is_verified)"
                  >
                    <span>
                      {{ verificationStatusName(userData.is_verified) }}
                    </span>
                  </vs-chip>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td>
                  <vs-chip transparent :color="statusColor(userData.status)">
                    <span>{{ statusName(userData.status) }}</span>
                  </vs-chip>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Role</td>
                <td>{{ roleName(userData.role) }}</td>
              </tr>
              <tr v-if="isRegionLevel">
                <td class="font-semibold">Region</td>
                <td>{{ regionName(userData.region) }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->

          <div id="account-manage-buttons" class="vx-col w-full flex mt-10">
            <vs-button
              v-if="$gate.can('edit_users')"
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              :to="{
                name: 'users-edit',
                params: { userId: $route.params.userId },
              }"
            >
              Edit
            </vs-button>

            <vs-button
              v-if="$gate.can('delete_users')"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              @click="confirmDeleteRecord"
            >
              Delete
            </vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { getCountryName } from '@/enums/CountryEnum';
import { getRegionName } from '@/enums/RegionEnum';
import { getRoleName, UserRoleEnum } from '@/enums/UserRoleEnum';
import { getStatusColor, getStatusName } from '@/enums/UserStatusEnum';
import {
  getVerificationStatusColor,
  getVerificationStatusName,
} from '@/enums/UserVerificationStatusEnum';
import { UserAction } from '@/store/actionTypes';

import moduleUser from '@/store/users/moduleUser';

export default {
  data() {
    return {
      userData: null,
      userNotFound: false,
    };
  },
  computed: {
    userId() {
      return +this.$route.params.userId;
    },
    statusName() {
      return (val) => getStatusName(val);
    },
    roleName() {
      return (val) => getRoleName(val);
    },
    regionName() {
      return (val) => getRegionName(val);
    },
    statusColor() {
      return (val) => getStatusColor(val);
    },
    verificationStatusName() {
      return (val) => getVerificationStatusName(val);
    },
    verificationStatusColor() {
      return (val) => getVerificationStatusColor(val);
    },
    countryName() {
      return (val) => getCountryName(val);
    },
    isRegionLevel() {
      return this.userData.role === UserRoleEnum.REGION.value;
    },
  },
  methods: {
    ...mapActions('user', [UserAction.fetchOne, UserAction.remove]),

    registerStoreModules() {
      if (!moduleUser.isRegistered) {
        this.$store.registerModule('user', moduleUser);
        moduleUser.isRegistered = true;
      }
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${this.userData.name}"?`,
        accept: this.deleteRecord,
        acceptText: 'Delete',
      });
    },
    async deleteRecord() {
      if (!this.$gate.can('delete_users')) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeUser(this.userData.id);

        this.showDeleteSuccess();

        this.$router.push({ name: 'users-list' }).catch();
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a user.');
      }

      this.$vs.loading.close();
    },
    showDeleteSuccess() {
      this.notifySuccess(
        'User deleted',
        'The user has been successfully deleted.'
      );
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      this.userData = await this.fetchUser(this.userId);
    } catch (error) {
      this.notifyError(error, 'Problem with loading users.');

      if (error.response.status === 404) {
        this.userNotFound = true;
        this.$vs.loading.close();
        return;
      }
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
.user-profile-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
