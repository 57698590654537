export const RegionEnum = Object.freeze({
  CHINA: { label: 'China', value: 'CHINA' },
  APAC: { label: 'APAC other', value: 'APAC' },
  US: { label: 'US', value: 'US' },
  AMERICAS: { label: 'Americas other', value: 'AMERICAS' },
  NORDIC: { label: 'Nordic', value: 'NORDIC' },
  UK: { label: 'UK', value: 'UK' },
  FRANCE: { label: 'France', value: 'FRANCE' },
  GERMANY: { label: 'Germany', value: 'GERMANY' },
  BENELUX: { label: 'Benelux', value: 'BENELUX' },
  RUSSIA: { label: 'Russia', value: 'RUSSIA' },
  EMEA: { label: 'Other EMEA', value: 'EMEA' },
  GLOBAL: { label: 'Global', value: 'GLOBAL' },
});

export const regionList = [...Object.values(RegionEnum)];

export const getRegionByValue = (val = null) => {
  if (val === null) return '';

  return regionList.find((el) => el.value === val);
};

export const getRegionName = (val = null) => {
  if (val === null) return '';

  return getRegionByValue(val).label;
};
