export const UserVerificationStatusEnum = Object.freeze({
  ALL: { label: 'All', value: 'all', color: 'black' },
  VERIFIED: { label: 'Verified', value: true, color: 'success' },
  NOT_VERIFIED: { label: 'Not Verified', value: false, color: 'danger' },
});

export const userVerificationStatusList = [
  ...Object.values(UserVerificationStatusEnum),
];

export const getVerificationStatusByValue = (val = null) => {
  if (val === null) return '';

  return userVerificationStatusList.find((el) => el.value === val);
};

export const getVerificationStatusName = (val = null) => {
  if (val === null) return '';

  return getVerificationStatusByValue(val).label;
};

export const getVerificationStatusColor = (val = null) => {
  if (val === null) return '';

  return getVerificationStatusByValue(val).color;
};
